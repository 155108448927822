import React from 'react';
import ReactGA from 'react-ga';
import Measure from 'react-measure';
import VimeoPlayer from 'react-player';

class Card extends React.Component {
  state = {
    cardBodyInnerDimensions: {
      width: -1,
      height: -1
    },
    cardBodyState: 'collapsed'
  }

  // getVideoMarkup(videoMarkup) {
  //   return { __html: videoMarkup };
  // }

  downloadFile = (fileUrl) => {
    // Create a new window, and set the url to the file url
    let newWindow = window.open(fileUrl, '_blank');
    newWindow.focus();

    // Send a ga event
    ReactGA.event({
      label: fileUrl
    }, function () {
      // Do nothing here
    });
  }

  render() {
    let card = this.props.card;
    let cardBodyCollapsedHeight = 128; // 8rem
    let cardContent = '';
    let customClass = 'alternate' in this.props && this.props.alternate === true ? 'card--transparent' : '';
    let sectionHeaderSubtitle = card.subtitle && card.subtitle.length > 0 ? card.subtitle : '';

    switch (card.type.slug) {
      case 'sectionHeader':
        cardContent = (
          <p>
            {/* new line to <br /> */}
            {sectionHeaderSubtitle.split('\n').map(function (item, i) {
              return (<span key={i}>{item}<br /></span>)
            })}
          </p>
        );
        break;
      case 'quote':
        cardContent = (
          <blockquote className="quote">

            <svg className={'quote__icon--' + this.props.colour} width="31" height="25" xmlns="http://www.w3.org/2000/svg"><text transform="translate(-145 -550)" fillRule="evenodd" fontFamily="BureauGrotesque-ThreeSeven" fontSize="80"><tspan x="143" y="611">“</tspan></text></svg>

            <p className="quote__body">{card.quoteText}</p>
            <footer>
              <cite className={'quote__cite quote__cite--' + this.props.colour}>
                {/* new line to <br /> */}
                {card.quoteSource.split('\n').map(function (item, i) {
                  return (<span key={i}>{item}<br /></span>)
                })}
              </cite>
            </footer>
          </blockquote>
        );
        break;
      case 'link':
        cardContent = (
          <ul className="card__link-list">
            {'links' in card && card.links.length !== 0 ? card.links.map((link, i) => {
              // Determine if link is to file with common filetype
              let fileType = link.url.match(/(pdf|doc|docx)$/i) ? link.url.split('.').pop() : 'other';

              if (fileType === 'other') {
                // Use a link element for pages
                return (
                  (<li key={i} className="card__link-list-item">
                    <ReactGA.OutboundLink eventLabel={link.url} to={link.url} className="card__link-list-link">
                      <span className="card__link-text">{link.text}</span>
                      <span className={'card__link-arrow card__link-arrow--' + this.props.colour}>
                        <svg width="10" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M2 14l6-6-6-6" strokeWidth="3" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"/></svg>
                      </span>
                    </ReactGA.OutboundLink>
                  </li>)
                )
              } else {
                // Use a button element for common filetypes
                return (
                  (<li key={i} className="card__link-list-item">
                    <button onClick={() => {this.downloadFile(link.url)}} data-file-url={link.url} className="card__link-list-link card__link-list-link--button">
                      <span className="card__link-text">{link.text}</span>
                      <span className="card__link-arrow icon-arrow--right"></span>
                    </button>
                  </li>)
                )
              }
            }) : ''}
          </ul>
        );
        break;
      case 'download':
        let fileUrl = (card.file.length !== 0) ? card.file[0].url : '#';
        let fileExtension = fileUrl.split('.').pop();
        let fileType = fileExtension.match(/(pdf|doc|docx)$/i) ? fileUrl.split('.').pop() : 'file';
        cardContent = (
          <div className="card__icon-wrap card__icon-wrap--download card--download">
            <button onClick={() => {this.downloadFile(fileUrl)}} data-file-url={fileUrl} className="card__link card__link--button" title="Download file">
              <p className="card__download-text">Download {fileType}</p>
              <svg className={'download__icon--' + this.props.colour} width="19" height="22" xmlns="http://www.w3.org/2000/svg"><g fillRule="evenodd"><path d="M1.032 22h16.936c.57 0 1.032-.51 1.032-1.14v-.72c0-.63-.462-1.14-1.032-1.14H1.032C.462 19 0 19.51 0 20.14v.72C0 21.49.462 22 1.032 22M16.04 8.03h-3.032V.96c0-.53-.429-.96-.958-.96h-6.1a.959.959 0 0 0-.958.96v7.07H1.96c-.854 0-1.28 1.033-.678 1.637l7.04 7.052a.957.957 0 0 0 1.355 0l7.04-7.052c.604-.604.177-1.637-.677-1.637"/></g></svg>
            </button>
          </div>
        );
        break;

      case 'video':
        if(card.vimeoEmbed && card.vimeoEmbed.length > 0) {
          var videoMarkup = card.vimeoEmbed;
        } else if (card.youtubeEmbed && card.youtubeEmbed.length > 0) {
          var videoMarkup = card.youtubeEmbed;
        }

        function trackPlay() {
          ReactGA.event({
            category: 'Video',
            action: 'Play',
            label: card.title
          });
        }

        cardContent = (
          <div className="card__video-wrap js-video">
            <VimeoPlayer
              className='react-player'
              url={videoMarkup}
              width='100%'
              height='100%'
              onPlay={trackPlay}
            />
          </div>
        );
        break;

      default:
        cardContent = '';
    }

    return (
      <div className={'card card--' + this.props.colour + ' card--' + card.type.slug + ' ' + customClass}>
        {card.title !== '' ? (<h3 className='card__title'>{card.title}</h3>) : ''}
        <div className={'card__body ' + this.state.cardBodyState }>
          <p>{'body' in card && card.body.length !== 0 ? card.body.split('\n').map(function(item, i) {
              return (<span key={i}>{item}<br /></span>)
            }) : ''}</p>
          {this.state.cardBodyInnerDimensions.height >= cardBodyCollapsedHeight && this.state.cardBodyState === 'collapsed' ? (
            <div className="card__gradient">
              <button className="card__show-more" onClick={() => { this.setState({cardBodyState: 'expanded'}) }}><span className="icon icon-arrow--down"></span>read more</button>
            </div>
          ) : ''}
        </div>
        {cardContent}
      </div>
    )
  }
}

export default Card;
