import React from "react";

const UnderstandingDiet = props => (
  <svg data-name="Layer 1" width={137} height={141} viewBox="0 0 74.51 88" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1-2">
        <path
          d="M47.53 39.82a4.28 4.28 0 0 1 .4 1.83 4.51 4.51 0 1 1-9 .6v-.6a4.52 4.52 0 0 1-9 .85 4 4 0 0 1 0-.85 4.45 4.45 0 0 1 1.7-3.51 4.52 4.52 0 0 1 .92-8.94 4.46 4.46 0 0 1 2.82 1 1 1 0 0 1 0-.24 4.52 4.52 0 0 1 9 0 4.53 4.53 0 0 1-.3 1.63 4.34 4.34 0 0 1 2.25-.6 4.51 4.51 0 0 1 1.25 8.84z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
          d="M45.053 41.79l3.459-20.084 2.592.446-3.459 20.085z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
          d="M47.197 44.757l3.458-20.084 2.592.446-3.458 20.085zM29.92 31.1c.37 1.63 2.2 16 .57 16.39S24.38 34.05 24 32.42a3.023 3.023 0 0 1 5.9-1.32z"
        />
        <path
          d="M21 24.49c2.28-.51 4.34 1.11 5.3 4.32m.1-.01c-.25-2.87 1.05-4.32 3.21-4.8"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
        />
        <ellipse
          cx={35.44}
          cy={36.72}
          rx={4.28}
          ry={4}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
        />
        <ellipse
          cx={40.08}
          cy={37.72}
          rx={3.64}
          ry={4}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
        />
        <path
          d="M73.46 46.55a5.62 5.62 0 0 0-2.41-4.43 6.14 6.14 0 0 0 2.41-4.77 6.32 6.32 0 0 0-3-5.21 8 8 0 0 0 0-7.53A5.86 5.86 0 0 0 73 20a5.8 5.8 0 0 0-2.13-4.35 7.1 7.1 0 0 0 1.62-4.48 7.74 7.74 0 0 0-8-7.44 8.31 8.31 0 0 0-3 .55A9.63 9.63 0 0 0 57 3.2a10.33 10.33 0 0 0-2.59.33 8.34 8.34 0 0 0-11.79-.18c-.18.17-.34.35-.5.53a8.46 8.46 0 0 0-8.3-.2 9.42 9.42 0 0 0-11 .21 10 10 0 0 0-3.66-.69 9.35 9.35 0 0 0-5.26 1.54 9.63 9.63 0 0 0-4.41-1c-4.42 0-8 2.77-8 6.2a6 6 0 0 0 3.7 5.23 5.68 5.68 0 0 0-2.68 4.62 5.38 5.38 0 0 0 1.74 3.81A6.38 6.38 0 0 0 1 29a6.24 6.24 0 0 0 2.64 5 8 8 0 0 0 .53 7 6 6 0 0 0-2.66 4.78 5.77 5.77 0 0 0 2.13 4.36A7.06 7.06 0 0 0 2 54.66a7.72 7.72 0 0 0 8 7.44 7.72 7.72 0 0 0 8-7.43 7.16 7.16 0 0 0-2.09-5 5.6 5.6 0 0 0 1.58-3.83 5.38 5.38 0 0 0-1-3.07 8 8 0 0 0 .27-11.31l-.27-.27A5.7 5.7 0 0 0 17 29a6 6 0 0 0-1.77-4.19 5.9 5.9 0 0 0 3.3-5 5.88 5.88 0 0 0-3.08-4.89 9.64 9.64 0 0 0 3.75.73A9.25 9.25 0 0 0 24.49 14a9.5 9.5 0 0 0 4 .86 9.58 9.58 0 0 0 2.82-.42A8.33 8.33 0 0 0 43 15.72a8.08 8.08 0 0 0 1.08-1 8.44 8.44 0 0 0 8.7 0 9.68 9.68 0 0 0 4.2.93 8.39 8.39 0 0 0 1-.06 8 8 0 0 0 .54.62A5.61 5.61 0 0 0 57 20a5.33 5.33 0 0 0 .77 2.75 8 8 0 0 0 .1 11.31c.14.14.29.27.44.4a5.74 5.74 0 0 0-.8 2.91 6.16 6.16 0 0 0 2.4 4.77 5.29 5.29 0 0 0-1.54 7.32 5.4 5.4 0 0 0 1.41 1.45 5.92 5.92 0 0 0-3.29 5 4.92 4.92 0 0 0 .31 1.7 8.62 8.62 0 0 0-2.46 1.19 9.68 9.68 0 0 0-4.2-.93A9.36 9.36 0 0 0 45 59.33a8.18 8.18 0 0 0-6.16-2.72 7.74 7.74 0 0 0-8 7.45 7.08 7.08 0 0 0 1.38 4.18 9.72 9.72 0 0 0-.75 3.8A9.39 9.39 0 0 0 33 77.26a6.57 6.57 0 0 0-1 3.53A6 6 0 0 0 37.62 87a6 6 0 0 0 5.67-6.2 6.65 6.65 0 0 0-1-3.5 9.33 9.33 0 0 0 1.55-5.3 11.22 11.22 0 0 0-.21-2A8.3 8.3 0 0 0 45 68.79a9.76 9.76 0 0 0 10.53-.15 9.55 9.55 0 0 0 4.2.94 10.11 10.11 0 0 0 2.89-.43 10.11 10.11 0 0 0 2.89.43c4.41 0 8-2.78 8-6.21a5.51 5.51 0 0 0-2.19-4.25 5.19 5.19 0 0 0 1.17-3.22 5.55 5.55 0 0 0-2.28-4.33 5.9 5.9 0 0 0 3.25-5.02z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          fill="#fff"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M22.54 37.77h29.38v14.62H22.54z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
          d="M22.54 42.66h29.38v4.83H22.54z"
        />
        <circle cx={25.47} cy={40.22} r={0.6}
          fill="currentColor"
          stroke="none" />
        <circle cx={25.47} cy={45.11} r={0.6}
          fill="currentColor"
          stroke="none" />
        <circle cx={25.47} cy={50} r={0.6}
          fill="currentColor"
          stroke="none" />
        <circle cx={48.99} cy={40.22} r={0.6}
          fill="currentColor"
          stroke="none" />
        <circle cx={48.99} cy={45.11} r={0.6}
          fill="currentColor"
          stroke="none" />
        <circle cx={48.99} cy={50} r={0.6}
          fill="currentColor"
          stroke="none" />
        <path
          d="M37.84 30.55A2.41 2.41 0 0 1 40 27.89h.08"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
          d="M24.34 33.55l3.21-.83"
        />
      </g>
    </g>
  </svg>
);

export default UnderstandingDiet;
