import has from 'lodash.has';

export default function getThemes () {
  return new Promise((resolve, reject) => {
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.protocol + '//' + window.location.host + '/api';
    const methodUrl = '/pages.json';

    let request = new window.XMLHttpRequest();
    request.open('GET', apiUrl + methodUrl, true);
    request.withCredentials = true;

    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        let response = JSON.parse(request.responseText);

        if ('pages' in response) {
          this.setState({
            pages: response.pages
          });
        }

        if ('welcome_text' in response) {
          this.setState({
            welcomeText: response.welcome_text
          });
        }

        // Update metadata
        if (has(this.state.pages, this.props.match.params.theme)) {
          this.updatePageMeta(this.state.pages[this.props.match.params.theme]);
        }

        resolve(response);
      } else {
        reject({
          status: request.status,
          statusText: request.statusText
        });
      }
    }.bind(this);

    request.onerror = function () {
      reject({
        status: request.status,
        statusText: request.statusText
      });
    };

    request.send();
  });
}
