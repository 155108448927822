import React from 'react';
import ReactGA from 'react-ga';

// import ccukLogo from '../images/ccuk_logo.svg';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content-wrap">
          <div className="footer__logo-wrap">
            <ReactGA.OutboundLink eventLabel="KCL" className="footer__logo-link">
              <img src="/images/kcl-logo.png" alt="King's College London" className="footer__logo" />
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink eventLabel="https://www.crohnsandcolitis.org.uk" to="https://www.crohnsandcolitis.org.uk" className="footer__logo-link">
              <img src="/images/crohnsandcolitis-logo.png" alt="Crohn's and Colitis UK - Fighting inflammatory bowel disease together" className="footer__logo" />
            </ReactGA.OutboundLink>
          </div>
          <div className="footer__links">
            <ReactGA.OutboundLink eventLabel="" to="" className="footer__links__link">
              Sitemap<span className="icon icon-arrow--right"></span>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink eventLabel="" to="" className="footer__links__link">
              Site help<span className="icon icon-arrow--right"></span>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink eventLabel="" to="" className="footer__links__link">
              Terms & conditions<span className="icon icon-arrow--right"></span>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink eventLabel="" to="" className="footer__links__link">
              Privacy policy<span className="icon icon-arrow--right"></span>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink eventLabel="" to="" className="footer__links__link">
              Accessibility<span className="icon icon-arrow--right"></span>
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink eventLabel="" to="" className="footer__links__link">
              Modern slavery statement<span className="icon icon-arrow--right"></span>
            </ReactGA.OutboundLink>
          </div>
          <div className="footer__legal-text">
            <p>© 2018 King's College London</p>
            <p>Strand | London WC2R 2LS | England | United Kingdom | Tel +44 (0)20 7836 5454</p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
