import React from "react";

const ManagingSymptoms = props => (
  <svg data-name="Layer 1" width={137} height={141} viewBox="0 0 109.38 77.89" {...props}>
    <g data-name="Layer 2">
      <g
        data-name="Layer 1-2"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path d="M16.88 4.46c2.68 7.82 1.94 15.05 17.71 18.39 0 0 0 5.57 4.75 8.54v8.38s-6.38 2.33-6.38 6.99c0 0-4.6-1.93-9.27 2.74 0 0-24.85-12.47-22.54 19.4" />
        <path d="M27.31.33c2.94 8.53 8.21 11 8.21 11s17.07-26 38.22 6.86-22.45 62.37-49.35 43.26c0 0-2.81 6.09-9.09-2.68-2.2-2.2-6.88 3.3-6.88 10.13" />
        <path
          d="M68.96 36.58l-21.4 24a1.69 1.69 0 0 1-2.38.24 1.66 1.66 0 0 1-.62-1.36V27.29"
          strokeMiterlimit={10}
        />
        <path
          d="M73.43 32.19l-1.74 4.56a1.49 1.49 0 0 1-2 .71 2 2 0 0 1-.63-.46c-1.49-1.46-1.86-2.28-3.61-3.42a.81.81 0 0 0-1.1.25l-3.57 5.62a1.59 1.59 0 0 1-2.67-1.71s3.43-5.37 3.9-6.14-2.31-2.19-2.91-1.24l-2.14 3.35a1.281 1.281 0 1 1-2.22-1.28l.06-.09 1.29-2a.82.82 0 0 0-.42-1.18A33.5 33.5 0 0 0 44.2 27.6c-1.18.06-2.14-1-1.78-2l1.74-4.56a1.29 1.29 0 0 1 1.23-.81l1.62-.07a34 34 0 0 1 24.82 9.46l1.13 1.17a1.33 1.33 0 0 1 .47 1.4z"
          strokeMiterlimit={10}
        />
        <circle cx={49.14} cy={49.1} r={1.59} strokeMiterlimit={10} />
        <circle cx={53.84} cy={43.68} r={1.59} strokeMiterlimit={10} />
        <circle cx={50.24} cy={37.27} r={1.59} strokeMiterlimit={10} />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M82.01 21.36l5.38-7.05 9.28 3.52 6.31-5.56m-33.13 50.1l8.72 1.57 1.1 9.87 7.83 3.08m-3.07-39.98l7.55-4.65 7.4 6.62 9-1.83M80 50.96l8.75.81 4.31 6.72 9.01 1.75"
        />
      </g>
    </g>
  </svg>
);

export default ManagingSymptoms;
