export default function getSearchResults (query) {
  return new Promise((resolve, reject) => {
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.protocol + '//' + window.location.host + '/api';
    const methodUrl = '/search-results.json';
    const queryString = `?search=${query}`;

    // let idsString = ids.join(',');
    let request = new window.XMLHttpRequest();
    request.open('GET', apiUrl + methodUrl + queryString, true);
    request.withCredentials = true;

    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        let response = JSON.parse(request.responseText);

        if ('results' in response) {
          // let newSearchState = mergeArraysOfObjects(this.state.searchResults, data.results, 'id');

          this.setState({
            searchResults: response.results
          });

          // Update metadata
          // if (has(this.state.pages, this.props.match.params.theme + '.children.' + this.props.match.params.topic)) {
          //   this.updatePageMeta(this.state.pages[this.props.match.params.theme]['children'][this.props.match.params.topic]);
          // }
          resolve(response);
        } else {
          reject({
            status: request.status,
            statusText: request.statusText
          });
        }
      } else {
        reject({
          status: request.status,
          statusText: request.statusText
        });
      }
    }.bind(this);

    request.onerror = function () {
      reject({
        status: request.status,
        statusText: request.statusText
      });
    };

    request.send();
  });
}
