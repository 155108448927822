import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { unregister } from './registerServiceWorker';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Container from './components/Container';

// Initialise react router
ReactGA.initialize('UA-68256264-8');

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  window.scrollTo(0, 0);
  return null;
}

const Root = () => {
  return (
    <Router>
      <React.Fragment>
        <Route path="/" component={logPageView} />
        <Switch>
          <Route path="/" component={Container} />
        </Switch>
      </React.Fragment>
    </Router>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

unregister();
