export default function getNavitems () {
  return new Promise((resolve, reject) => {
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.protocol + '//' + window.location.host + '/api';
    const methodUrl = '/main-nav.json';

    let request = new window.XMLHttpRequest();
    request.open('GET', apiUrl + methodUrl, true);
    request.withCredentials = true;

    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        let response = JSON.parse(request.response);

        if (response.data.length > 0 && 'links' in response.data[0]) {
          this.setState({
            navLinks: response.data[0].links
          });
          resolve(response);
        } else {
          reject(response);
        }

        // Update metadata
        // if (has(this.state., this.props.match.params.theme)) {
        //   this.updatePageMeta(this.state.pages[this.props.match.params.theme]);
        // }
      } else {
        // We reached our target server, but it returned an error
        reject({
          status: this.status,
          statusText: request.statusText
        });
      }
    }.bind(this);

    request.onerror = function () {
      reject({
        status: this.status,
        statusText: request.statusText
      });
    };

    request.send();
  });
}
