import React from 'react';
import { Link } from 'react-router-dom';
import SVGIcon from './SVGIcon';

class TopicLink extends React.Component {
  render () {
    let topicIcon = this.props.icon.value;

    return (
      <Link to={this.props.linkUrl} className={'topic-link topic-link__link topic-link--' + this.props.colour}>
        <div className='topic-link__text'>
          {this.props.title}
        </div>
        <SVGIcon icon={topicIcon} colour={this.props.colourHex} className="topic-link__icon"/>
      </Link>
    );
  }
}

export default TopicLink;
