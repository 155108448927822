import React, { Component } from 'react';
import ReactGA from 'react-ga';

import 'promise-polyfill/src/polyfill';
import 'es6-object-assign/auto';
import "ie-array-find-polyfill";

// import CSSTransitionGroup from 'react-transition-group';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import Header from './Header';
import Welcome from './Welcome'
import LoginForm from './LoginForm';
import Theme from './Theme';
import Topic from './Topic';
import Footer from './Footer';
import NotFound from './NotFound';
import Search from './Search';

import getAuthStatus from '../api/getAuthStatus';
import getPages from '../api/getPages';
import getTopic from '../api/getTopic';
import getNavItems from '../api/getNavItems';
import getWelcomeText from '../api/getWelcomeText';
// import getSearchResults from '../api/getSearchResults';

// import checkForCookie from '../helpers/checkForCookie';
import objectIsEmpty from '../helpers/objectIsEmpty';

class Container extends Component {
  constructor() {
    super();

    // this.checkForCookie = checkForCookie.bind(this);
    this.getAuthStatus = getAuthStatus.bind(this);
    this.getPages = getPages.bind(this);
    this.getTopic = getTopic.bind(this);
    this.getNavItems = getNavItems.bind(this);
    this.getWelcomeText = getWelcomeText.bind(this);
    this.updatePageMeta = this.updatePageMeta.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleSearchChange(e) {
    e.preventDefault();
    this.props.history.push('/search');
    const query = document.getElementById('js-search-input').value;
    this.setState({ searchQuery: query });
  }

  // This is the global state
  state = {
    pages: [],
    cards: [],
    welcomeText: '',
    pageMeta: {
      'title': '',
      'description': ''
    },
    navLinks: [],
    searchQuery: '',
    loggedInUser: {},
    prevPath: '/'
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ prevPath: this.props.location })
    }
  }

  componentWillMount() {
    this.getAuthStatus()
      .then((userData) => {
        this.setState({ loggedInUser: userData });
        // Send the User Id with Analytics events
        ReactGA.set({ userId: userData.data[0].id });
        this.getNavItems();
        this.getWelcomeText();
        this.getPages();
      })
      .catch((e) => {
        // Set User Id to 0, because we can't unset it
        ReactGA.set({ userId: 0 });
        // if (e.status === 403) {
        //   Redirect to login
          this.props.history.push('/login');
        // }
      });

    // this.checkForCookie();
  }

  updatePageMeta(currentCategory) {
    let newPageMeta = {
      ...this.state.pageMeta
    };

    if (!objectIsEmpty(currentCategory)) {
      newPageMeta.title = 'name' in currentCategory ? currentCategory.name : '';
      newPageMeta.description = 'cat_seo_description' in currentCategory ? currentCategory.cat_seo_description : 'description' in currentCategory ? currentCategory.description : '';

      this.setState({ pageMeta: newPageMeta });
    }
  }

  render() {
    return (
      <div className="container">
        <Helmet>
          <title>{this.state.pageMeta.title !== '' ? this.state.pageMeta.title + ' | Food in IBD ' : 'Food in IBD'}</title>
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content={this.state.pageMeta.title} />
          <meta property="og:description" content={this.state.pageMeta.description !== '' ? this.state.pageMeta.description : this.state.welcomeText} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@CrohnsColitisUK" />
          <meta name="twitter:title" content={this.state.pageMeta.title} />
          <meta name="twitter:description" content={this.state.pageMeta.description !== '' ? this.state.pageMeta.description : this.state.welcomeText} />
        </Helmet>
        <Header pages={this.state.pages} handleSearchChange={this.handleSearchChange} navItems={this.state.navLinks} {...this.props} />
          <Switch>
            <Route exact path="/login" render={ (routeProps) =>
              <LoginForm redirectTo={this.state.prevPath} getPages={this.getPages} getNavItems={this.getNavItems} getWelcomeText={this.getWelcomeText} {...routeProps} />
            } />
            <Route exact path="/" render={ (routeProps) =>
              <Welcome pages={this.state.pages} welcomeText={this.state.welcomeText} {...routeProps} />
            } />
            <Route path="/search" render={ (routeProps) =>
              <Search searchQuery={this.state.searchQuery} updatePageMeta={this.updatePageMeta} {...routeProps} />
            } />
            <Route exact path="/:theme" render={ (routeProps) =>
              <Theme pages={this.state.pages} updatePageMeta={this.updatePageMeta} {...routeProps} />
            } />
            <Route exact path="/:theme/:topic" render={ (routeProps) =>
              <Topic pages={this.state.pages} cards={this.state.cards} getTopic={this.getTopic} updatePageMeta={this.updatePageMeta} {...routeProps} />
            } />
            <Route path="*" component={NotFound}/>
          </Switch>
        <Footer />
      </div>
    );
  }
}

export default Container;
