import React from 'react';
// import { withRouter } from 'react-router-dom';

function NoResults() {
  return (
    <div className="search no-results grid-item--two-thirds">
      No results found
    </div>
  )
}

export default NoResults;
