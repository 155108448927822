export default function getAuthStatus () {
  return new Promise(function (resolve, reject) {
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.protocol + '//' + window.location.host + '/api';
    const methodUrl = '/auth-status.json';
    const request = new window.XMLHttpRequest();

    request.open('GET', apiUrl + methodUrl, true);
    request.withCredentials = true;
    request.onload = function () {
      if (this.status >= 200 && this.status < 400) {
        resolve(JSON.parse(request.response));
      } else {
        reject({
          status: this.status,
          statusText: request.statusText
        });
      }
    };
    request.onerror = function () {
      reject({
        status: this.status,
        statusText: request.statusText
      });
    };
    request.send();
  });
}
