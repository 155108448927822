import React from 'react';
// import CSSTransitionGroup from 'react-transition-group';
// import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SVGIcon from './SVGIcon';

class SearchBar extends React.Component {

  render () {
    return (
      <div className="search-container">
        <Link to="/" className="header__home-link">
          <h1 className="header__title">Food and IBD</h1>
        </Link>
        <form action="search" onSubmit={this.props.handleSearchChange} className="search-bar">
          <span className="u-visually-hidden">Search form</span>
          <input type="text" name="search" placeholder="Search" id="js-search-input" className="search-bar__input"></input>
          <button type="submit" className="search__button" title="Submit search"><SVGIcon icon='search' /></button>
        </form>
      </div>
    )
  }
}

export default withRouter(SearchBar);
