import React from "react";

const Gender = props => (
  <svg width={125} height={147} viewBox="0 0 125 147" {...props}>
    <path
      d="M111.217 60.954A37.983 37.983 0 0 1 85.542 86.62h-.003c2.542-23.514-14.468-44.635-37.99-47.175a43.002 43.002 0 0 0-9.198 0C44.293 19.33 65.421 7.839 85.542 13.78c20.121 5.938 31.615 27.06 25.675 47.174zm-30.8 26.777c-20.568 3.28-39.898-10.73-43.179-31.29a37.716 37.716 0 0 1 0-11.873c20.57-3.277 39.9 10.735 43.18 31.298.626 3.93.626 7.935 0 11.865zm-37.52 32.32c-20.98-.035-37.96-17.064-37.926-38.036.027-16.775 11.06-31.547 27.144-36.335-2.541 23.515 14.468 44.635 37.989 47.175 3.057.33 6.143.33 9.2 0-4.79 16.112-19.592 27.17-36.407 27.196zM107.844 0v4.9h8.788l-13.376 13.372C85.67 2.509 58.631 3.98 42.861 21.562a42.747 42.747 0 0 0-9.805 18.822c-23.038 5.413-37.325 28.47-31.91 51.5 4.242 18.038 19.606 31.307 38.076 32.88v7.537h-9.804v4.898h9.804V147h4.902v-9.8h9.802v-4.9h-9.802v-7.412c19.398-.576 35.996-14.097 40.476-32.974 23.003-5.369 37.296-28.361 31.924-51.357a42.745 42.745 0 0 0-9.805-18.82l13.379-13.373v8.786H125V0h-17.156z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Gender;
