import React from 'react';
import { Link } from 'react-router-dom';

import objectIsEmpty from '../helpers/objectIsEmpty';
import SVGIcon from './SVGIcon';

class ThemeLink extends React.Component {
  render() {
    let theme = this.props.theme;
    let themeColour = !objectIsEmpty(theme) && 'colour' in theme ? theme.colour : '';
    let themeIcon = theme.icon.value;
    let themeLinkBgImg = 'url("/images/homepage-pic-1.png")';

    return (
      <div className={'themes-list__item theme-link theme-link--' + themeColour} style={{backgroundImage: themeLinkBgImg}}>
      <div className={'u-mq-hide-lg theme-link__overlay--' + themeColour}></div>
        <Link to={theme.slug} className="theme-link__link">
          <div className="theme-link__icon">
            <SVGIcon icon={themeIcon} colour='#ffffff' />
          </div>
          <div className="theme-link__text">
            {theme.title}
          </div>
        </Link>
      </div>
    )
  }
}

export default ThemeLink;
