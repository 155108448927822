import React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {
  render () {
    return (
      <main className="theme">
        <div className="content-wrap">
          <div className="">
            <div className="topics-list">
              <div className="">
                <div className="summary summary--white summary--theme topics-list__item">
                  <h1 className="summary__title brand-line brand-line--purple brand-line--large">Not Found</h1>
                  <div className="summary__description">
                    <p>We're sorry, the page you are looking for may have been moved or deleted.</p><br />
                    <p><Link to="/">Click here to visit the Food and IBD homepage</Link> or click one of the links above to find out information about a specific topic.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default NotFound;
