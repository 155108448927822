import React from "react";

const UnderstandingIbd = props => (
  <svg data-name="Layer 1" width={137} height={141} viewBox="0 0 50.66 79.61" {...props}>
    <g data-name="Layer 2">
      <g
        data-name="Layer 1-2"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          d="M40 19.69a2.47 2.47 0 0 1-2.46 2.45h-1.8a12.54 12.54 0 0 1-25.08 0H8.91a2.46 2.46 0 0 1-.31-4.91 1.61 1.61 0 0 1 .31 0h.18v-1.75A14.48 14.48 0 0 1 23.57 1h5.86a7.89 7.89 0 0 1 7.89 7.89v8.34h.17A2.42 2.42 0 0 1 40 19.56z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.91 17.23a5.63 5.63 0 0 0 3.72.48 1.3 1.3 0 0 0 .92-1.55c-.28-1.35-.36-3.65 2.24-4.09 3.72-.62 14.25 6.3 21.47-1.65M20.57 29.49l6.73-.93"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.24 33v5.1l-1.1.68a9.44 9.44 0 0 1-9.88 0l-1.11-.68V33"
          strokeMiterlimit={10}
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M28.09 20.31v1.26m-8.87-1.26v1.26M29.24 37c11.89 0 17.69 13.42 20 18a4.06 4.06 0 0 1-.05 3.7c-2.21 4.13-9.38 11.07-17 13.79a4.63 4.63 0 0 1-5.92-2.8 4.8 4.8 0 0 1-.27-1.6 4.7 4.7 0 0 1 3.8-4.65c2.73-.48 6.33-2 9.13-6.71a49.15 49.15 0 0 0-4-6.09m-26.7-2.48l1.7 6.76v19.23a4.46 4.46 0 0 1-4.46 4.46A4.48 4.48 0 0 1 1 74.15V49.47a11.16 11.16 0 0 1 8.7-10.88l7.45-1.67"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.17 76.13h21.52V72.3"
        />
      </g>
    </g>
  </svg>
);

export default UnderstandingIbd;
