import React from 'react';
import CSSTransitionGroup from 'react-transition-group';
import ReactGA from 'react-ga';

import objectIsEmpty from '../helpers/objectIsEmpty';

import Card from './Card';
import NotFound from './NotFound';
import SVGIcon from './SVGIcon';

class Topic extends React.Component {
  componentDidMount () {
    this.props.getTopic(this.props.match.params.topic)
      .catch((e) => {
        if (e.status === 403) {
          // Set User Id to 0, because we can't unset it
          ReactGA.set({ userId: 0 });
          // Redirect to login
          this.props.history.push('/login');
        }
      });
  }

  componentDidUpdate (prevProps) {
    // respond to parameter change in scenario 3
    let oldTopic = prevProps.match.params.topic
    let newTopic = this.props.match.params.topic
    if (newTopic !== oldTopic) {
      this.props.getTopic(this.props.match.params.topic)
      .catch((e) => {
        if (e.status === 403) {
          // Redirect to login
          this.props.history.push('/login');
        }
      });
    }
  }

  render () {
    let pages = this.props.pages;
    let cards = this.props.cards;
    let currentTheme = {};
    let currentTopic = {};
    let topicCards = [];

    // Delay this until we've got a response from the API, else we get a
    // flash of the 404 component
    if (!objectIsEmpty(pages)) {
      // Find the matching top-level page in the pages object
      let filteredPages = pages.filter((page) => {
        return page.slug === this.props.match.params.theme;
      });

      if (filteredPages.length > 0) {
        currentTheme = filteredPages[0];
      } else {
        return (
          <NotFound />
        )
      }
    }

    // Get the current Topic from the current Theme
    if (!objectIsEmpty(currentTheme) && 'children' in currentTheme) {
      // Find the matching second-level page in the parent page
      let filteredPages = currentTheme.children.filter((page) => {
        return page.slug === this.props.match.params.topic;
      });

      if (filteredPages.length > 0) {
        currentTopic = filteredPages[0];
      } else {
        return (
          <NotFound />
        )
      }
    }

    // Get the colour from the current theme
    let topicColour = !objectIsEmpty(currentTopic) && 'colour' in currentTopic ? currentTopic.colour : '';
    let topicColourHex = !objectIsEmpty(currentTopic) && 'colourHexCode' in currentTopic ? currentTopic.colourHexCode : '';

    // Get the title from the current topic
    let topicTitle = objectIsEmpty(currentTopic) ? '' : (<h2 className="hero__title">{currentTopic.title}</h2>);

    // Get the topic description
    let topicDescription = !objectIsEmpty(currentTopic) && 'intro' in currentTopic && currentTopic.intro !== '' ? (
      <p className="hero__description">
        {currentTopic.intro.split('\n').map(function (item, i) {
          return (<span key={i}>{item}<br /></span>);
        })}
      </p>
    ) : '';

    let topicIcon = !objectIsEmpty(currentTopic) && 'icon' in currentTopic ? currentTopic.icon.value : 'medicine';

    // Get an array of entry IDs in the current topic
    let topicContentBlocks = (!objectIsEmpty(currentTopic) && 'contentBlocks' in currentTopic) ? currentTopic.contentBlocks : [];

    // Make an array of cards in this topic
    if (cards.length > 0) {
      topicContentBlocks.forEach((block) => {
        let entryId = block.id;
        topicCards.push(cards.find(card => card.id === entryId));
      })
    }

    return (
      <main className={'background background--' + topicIcon + '--' + topicColour}>
        <div className="content-wrap">

          {/* Hero */}
          <div className="hero">
            <div className={'hero__icon-box hero__icon-box--topic hero__icon-box--topic--' + topicColour + ' hero__icon-box--topic--' + topicIcon} >
              <SVGIcon icon={topicIcon} colour={topicColourHex} />
            </div>
            <div className={'hero__title-box hero--' + topicColour}>
              {topicTitle}
              {topicDescription}
            </div>
          </div>

          {/* Main content */}
          <div className="grid">
            {/* Show quotes in left col on desktop (hidden in single col) */}
            {topicCards.length !== 0 ? topicCards.map((card, i) => {
              return card !== undefined && card.type.slug === 'quote' ? (
                <div key={card.id} className="grid-item--third--left">
                  <Card card={card} colour={topicColour} alternate={true} />
                </div>
              ) : false;
            }) : false}

            {/* Cards */}
            {topicCards.length !== 0 ? topicCards.map((card, i) => {
              return card !== undefined ? (
                <div key={i} className={(card.type.slug === 'quote' ? ' u-mq-hide-sm' : ' grid-item--two-thirds')}>
                  <Card card={card} key={card.id} colour={topicColour} />
                </div>
              ) : false;
            }) : false}
          </div>
        </div>
      </main>
    )
  }
}

export default Topic;
