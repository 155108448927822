import React from 'react';
import ReactGA from 'react-ga';

import login from '../api/login';
import getAuthStatus from '../api/getAuthStatus';

class LoginForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      error: '',
      formErrors: {
        username: '',
        password: ''
      },
      usernameValid: false,
      passwordValid: false,
      formValid: false
    }

    this.login = login.bind(this);
    this.getAuthStatus = getAuthStatus.bind(this);
    this.getPages = this.props.getPages;
    this.getNavItems = this.props.getNavItems;
    this.getWelcomeText = this.props.getWelcomeText;
  }

  handleSubmit (event) {
    event.preventDefault();
    this.setState({ error: '' });
    this.login({
      loginName: this.state.username,
      password: this.state.password
    })
      .then(() => {
        this.getAuthStatus()
          .then((userData) => {
            // Store the user
            this.setState({ loggedInUser: userData });
            // Send the User Id with Analytics events
            ReactGA.set({ userId: userData.data[0].id });
            // Send a login event
            ReactGA.event({
              category: 'User',
              action: 'Login'
            });

            this.getNavItems();
            this.getPages();
            this.getWelcomeText();

            // Redirect to the previous page (unless it's null or '/login')
            let redirectUrl = (this.props.redirectTo !== null && this.props.redirectTo !== '/login') ? this.props.redirectTo : '/';
            this.props.history.push(redirectUrl);
          });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ error: 'Invalid username or password' });
      });
  }

  handleUserInput (event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState( { [name]: value },
      () => { this.validateField(name, value) });
  }

  validateField (fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let usernameValid = this.state.usernameValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case 'username':
        usernameValid = value.length >= 2;
        fieldValidationErrors.email = usernameValid ? '' : ' is invalid';
        break;
      case 'password':
        passwordValid = value.length >= 6;
        fieldValidationErrors.password = passwordValid ? '': ' is too short';
        break;
      default:
        break;
    }

    this.setState({
      formErrors: fieldValidationErrors,
      usernameValid: usernameValid,
      passwordValid: passwordValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({ formValid: this.state.usernameValid && this.state.passwordValid });
  }

  render () {
  // let bgImage = 'url("/images/log-in-bg.png")';

    return (
      <main className="login-bg">
        <div className="content-wrap">
            <div className="login__welcome-message">
              <h2>Welcome to the Promote Food in IBD study</h2>
              <p>Please sign in below</p>
            </div>
          <div className="grid">
            <div className="grid-item--third---left hero__icon-box hero__icon-box--white-bg hero__icon-box--blue-outline">
              <img src="/svg/lock.svg" alt="" className="hero__icon" />
            </div>

            <form onSubmit={(event) => this.handleSubmit(event)} className="login-form grid-item--two-thirds">
              <h1 className="login-form__title">LOGIN</h1>
              <div className="errors-container">
                {this.state.error}
              </div>
              <div className="input-wrap">
                <label htmlFor="username" className="login-form__label">Username</label>
                <input type="text" id="username" name="username" value={this.state.username} onChange={(event) => this.handleUserInput(event)} className="login-form__input"/>
              </div>
              <div className="input-wrap">
                <label htmlFor="password" className="login-form__label">Password</label>
                <input type="password" id="password" name="password" value={this.state.password} onChange={(event) => this.handleUserInput(event)}  className="login-form__input"/>
              </div>
              <button type="submit" disabled={!this.state.formValid} className="login-form__submit">Login <img src="/svg/arrow--right--white.svg" className="login-form__submit__arrow"/></button>
            </form>
          </div>
        </div>
      </main>
    )
  }
};

export default LoginForm;
