import React from 'react';
import CSSTransitionGroup from 'react-transition-group';
import Measure from 'react-measure';

import TopicLink from './TopicLink';
import NotFound from './NotFound';
import SVGIcon from './SVGIcon';

import objectIsEmpty from '../helpers/objectIsEmpty';

class Theme extends React.Component {
  state = {
    iconBoxDimensions: {
      width: -1,
      height: -1
    }
  }

  componentWillMount() {
    this.props.updatePageMeta(this.props.pages[this.props.match.params.theme]);
  }

  render() {
    let pages = this.props.pages;
    let pathname = this.props.location.pathname;
    let currentTheme = {};

    // Delay this until we've got a response from the API, else we get a
    // flash of the 404 component
    if (!objectIsEmpty(pages)) {
      // Find the matching top-level page in the pages object
      let filteredPages = pages.filter((page) => {
        return page.slug === this.props.match.params.theme;
      });

      if (filteredPages.length > 0) {
        currentTheme = filteredPages[0];
      } else {
        return (
          <NotFound />
        )
      }
    }

    // Get the title from the current theme
    let themeTitle = !objectIsEmpty(currentTheme) && 'title' in currentTheme ? (<h2 className="hero__title">{currentTheme.title}</h2>) : '';

    // Get the topic description
    let themeDescription = !objectIsEmpty(currentTheme) && 'intro' in currentTheme && currentTheme.intro !== '' ? (
      <p className="hero__description">
        {currentTheme.intro.split('\n').map(function (item, i) {
          return (<span key={i}>{item}<br /></span>);
        })}
      </p>
    ) : '';

    let themeColour = !objectIsEmpty(currentTheme) && 'colour' in currentTheme ? currentTheme.colour : '';
    let themeIcon = !objectIsEmpty(currentTheme) && 'icon' in currentTheme ? currentTheme.icon.value : 'medicine';

    // Get an object containing the child topics
    let childTopics = ('children' in currentTheme ? currentTheme.children : []);

    return (
      <main className={'background background--' + themeIcon +'--' + themeColour + ' theme theme--' + themeColour}>
        <div className="content-wrap">

          {/* <div className=""> */}
            {/* Hero */}
            <div className="hero">
              <div className={'hero__icon-box hero__icon-box--' + themeColour} style={{ marginBottom: 0 }}>
                <SVGIcon icon={themeIcon} colour='#ffffff' />
              </div>
              <div className={'hero__title-box hero--' + themeColour}>
                <div className={"hero__title-box__overlay"}></div>
                {themeTitle}
                {themeDescription}
              </div>
            </div>


            <div className='theme__topics grid'>
              {childTopics.map(function (topic) {
                return (
                  <div key={topic.id} className="topics-list__item">
                    <TopicLink
                      linkUrl={`${pathname}/${topic.slug}`}
                      title={topic.title}
                      colour={topic.colour}
                      colourHex={topic.colourHexCode}
                      icon={topic.icon}
                    />
                  </div>
                );
              })}
            </div>
          {/* </div> */}
        </div>
      </main>
    )
  }
}

export default Theme;
