import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
// import CSSTransitionGroup from 'react-transition-group';
// import Measure from 'react-measure';

import NotFound from './NotFound';
import getSearchResults from '../api/getSearchResults';
import Card from './Card';
import SVGIcon from './SVGIcon';

import objectIsEmpty from '../helpers/objectIsEmpty';
import NoResults from './NoResults';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchResults = getSearchResults.bind(this);
  }

  state = {
    searchResults: []
  }

  componentWillMount() {
    // this.props.updatePageMeta(this.props.pages[this.props.match.params.theme]);
    this.getSearchResults(this.props.searchQuery)
      .catch((e) => {
        if (e.status === 403) {
          // Set User Id to 0, because we can't unset it
          ReactGA.set({ userId: 0 });
          // Redirect to login
          this.props.history.push('/login');
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    this.getSearchResults(nextProps.searchQuery)
      .catch((e) => {
        if (e.status === 403) {
          // Redirect to login
          this.props.history.push('/login');
        }
      });
  }

  render() {
    let searchResultsContent;
    if (this.state.searchResults.length !== 0) {
      searchResultsContent = this.state.searchResults.map((card, i) => {
        return (
          // <Link to={card.url} key={i} className={'grid-item--two-thirds' + (card.type.slug === 'quote' ? ' u-mq-hide-sm' : '')}>
          //   <Card card={card} key={card.id} colour='green' />
          // </Link>
          <div key={i} className={'grid-item--two-thirds' + (card.type.slug === 'quote' ? ' u-mq-hide-sm' : '')}>
            <Card card={card} key={card.id} colour='blue' />
          </div>
        );
      })
    } else {
      searchResultsContent = <NoResults />
    }

    let topicBgImg = 'url("/images/search-bg.png")';

    return (
      <main className='search' style={{backgroundImage: topicBgImg}}>
        <div className="content-wrap">

          {/* Hero */}
          <div className="hero">
            <div className="hero__icon-box hero__icon-box--blue-outline hero__icon-box--white-bg">
              <img src="/svg/search.svg" alt="" className="hero__icon" />
              {/* <img src={topicIcon} alt="" className="hero__icon" /> */}
              {/* <SVGIcon icon='Gender' /> */}
            </div>
            <div className="hero__title-box">
              <h2 className="hero__title">Search results</h2>
              <p className="hero__description">"{this.props.searchQuery}"</p>
            </div>
          </div>

          <div className="grid">
            {/* Empty div to push the cards to the right hand 2 columns (could be better) */}
            <div className="grid-item--third--left"></div>
            {searchResultsContent}
          </div>

        </div>
      </main>
    )
  }
}

export default Search;
