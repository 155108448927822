import React from 'react';
import { Link } from 'react-router-dom';
import Gender from './svg/Gender';
import Medicine from './svg/Medicine';
import Hospital from './svg/Hospital';
import Pregnant from './svg/Pregnant';
import DietaryApproaches from './svg/DietaryApproaches';
import ManagingSymptoms from './svg/ManagingSymptoms';
import NewlyDiagnosed from './svg/NewlyDiagnosed2';
import SocialImpact from './svg/SocialImpact2';
import UnderstandingDiet from './svg/UnderstandingDiet';
import UnderstandingIbd from './svg/UnderstandingIbd';
import Search from './svg/Search';

class SVGIcon extends React.Component {
  renderIcon() {
    switch(this.props.icon){
      case 'gender': return <Gender colour={this.props.colour} />;
      case 'medicine': return <Medicine colour={this.props.colour} />;
      case 'pregnant': return <Pregnant colour={this.props.colour} />;
      case 'hospital': return <Hospital colour={this.props.colour} />;
      case 'dietaryApproaches': return <DietaryApproaches colour={this.props.colour} />;
      case 'managingSymptoms': return <ManagingSymptoms colour={this.props.colour} />;
      case 'newlyDiagnosed2': return <NewlyDiagnosed colour={this.props.colour} />;
      case 'socialImpact2': return <SocialImpact colour={this.props.colour} />;
      case 'understandingDiet': return <UnderstandingDiet colour={this.props.colour} />;
      case 'understandingIbd': return <UnderstandingIbd colour={this.props.colour} />;

      case 'search': return <Search />;
    }
  }
  render () {
    const iconComponent = this.renderIcon();
    return (
      <React.Fragment>{iconComponent}</React.Fragment>
    );
  }
}

export default SVGIcon;
