import React from "react";

const Medicine = props => (
  <svg width={133} height={133} viewBox="0 0 133 133" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M0 0v26.6h4.438v4.434H0V133h71V31.034h-4.438v-4.433H71V0H0zm4.438 22.167h62.124V4.434H4.438v17.733zm0 13.299h4.438v-8.865h53.249v8.865h4.438v8.868H13.312v66.5h53.25v17.733H4.438V35.466zM17.75 106.4h48.812V48.767H17.75V106.4z" />
      <path
        d="M46.078 35.586h-6.553v-13.24h6.553c3.62 0 6.553 2.962 6.553 6.62 0 3.656-2.933 6.62-6.553 6.62zm-10.922 0h-6.553c-3.62 0-6.553-2.964-6.553-6.62 0-3.658 2.933-6.62 6.553-6.62h6.553v13.24zM17.68 17.93H4.573v-6.62c0-3.657 2.935-6.62 6.555-6.62 3.618 0 6.553 2.963 6.553 6.62v6.62zm0 11.035c0 3.656-2.935 6.62-6.553 6.62-3.62 0-6.555-2.964-6.555-6.62v-6.62H17.68v6.62zM46.078 17.93H28.603a10.737 10.737 0 0 0-6.553 2.264V11.31C22.05 5.217 17.159.276 11.128.276 5.095.276.205 5.217.205 11.311v17.655C.188 35.042 5.05 39.98 11.062 39.999a10.849 10.849 0 0 0 8.802-4.482A10.846 10.846 0 0 0 28.603 40h17.475C52.11 40 57 35.06 57 28.966S52.11 17.93 46.078 17.93z"
        mask="url(#b)"
        transform="translate(76 93)"
      />
      <path d="M22 62h40v-5H22zm0 14h9v-5h-9zm14 0h26v-5H36zm79.5-39.5h-9v9h-9v-9h-9v-9h9v-9h9v9h9v9zM111 23v-9H93v9h-9v18h9v9h18v-9h9V23h-9z" />
      <path d="M102.5 53.585c-12.196 0-22.084-9.889-22.084-22.084.013-12.192 9.893-22.07 22.083-22.084 12.197 0 22.084 9.887 22.084 22.084 0 12.195-9.887 22.084-22.084 22.084M102.5 5C87.864 5 76 16.864 76 31.5c.015 14.63 11.87 26.485 26.5 26.5 14.636 0 26.5-11.864 26.5-26.5S117.136 5 102.5 5" />
    </g>
  </svg>
);

export default Medicine;
