function serialize (obj) {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
}

export default function login (credentials) {
  return new Promise(function (resolve, reject) {
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.protocol + '//' + window.location.host + '/api/';
    const request = new window.XMLHttpRequest();
    const data = serialize(Object.assign(credentials, { action: 'users/login' }));

    request.open('POST', apiUrl, true);
    request.setRequestHeader('Accept', 'application/json');
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.withCredentials = true;
    request.onload = function () {
      if (this.status >= 200 && this.status < 400) {
        let response = JSON.parse(request.response);
        // Craft returns a 200 even if there was an error, look at 'success'
        if ('success' in response && response.success === true) {
          resolve(response);
        } else {
          reject(response);
        }
      } else {
        reject({
          status: this.status,
          statusText: request.statusText
        });
      }
    };
    request.onerror = function () {
      reject({
        status: this.status,
        statusText: request.statusText
      });
    };
    request.send(data);
  });
}
