import React from 'react';
import { Link } from 'react-router-dom';

import objectIsEmpty from '../helpers/objectIsEmpty';

import SearchBar from './SearchBar';

class Header extends React.Component {

  // constructor() {
  //   super();
  //   this.handleSearchChange = this.handleSearchChange();
  // }

  componentWillMount () {
  }

  render () {
    // Get an object containing all top-level sections or 'themes'
    let navItems = this.props.navItems;

    // This isn't a history.back() link, but an 'up' link
    let backLink = !objectIsEmpty(this.props.match.params) ? (
      <Link to={'topic' in this.props.match.params ? '/' + this.props.match.params.theme : '/'} className="header__back-link">
        <span className="icon icon-arrow--left"></span>
        <span className="u-visually-hidden">Back</span>
      </Link>)
    : '';

    return (
      <header className="header">
        <div className="content-wrap">
          {backLink}
          <SearchBar handleSearchChange={this.props.handleSearchChange} />
          <nav className="header__nav">
            <ul className="header__nav-list">
              {navItems.map(function (link, i) {
                return (
                  <li key={'nav-link--' + i} className="header__nav-item">
                    <Link to={'/' + link.slug} className={'header__nav-link'}>
                      {link.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
