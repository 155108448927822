import mergeArraysOfObjects from '../helpers/mergeArraysOfObjects';
import has from 'lodash.has';

export default function getTopic (topicSlug) {
  return new Promise((resolve, reject) => {
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.protocol + '//' + window.location.host + '/api';
    const methodUrl = '/cards.json';

    let request = new window.XMLHttpRequest();
    request.open('GET', apiUrl + methodUrl + '?relatedTo=' + topicSlug, true);
    request.withCredentials = true;

    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        // Success!
        let response = JSON.parse(request.responseText);

        if ('cards' in response) {
          let newCardsState = mergeArraysOfObjects(this.state.cards, response.cards, 'id');

          this.setState({
            cards: newCardsState
          });

          // Update metadata
          if (has(this.state.pages, this.props.match.params.theme + '.children.' + this.props.match.params.topic)) {
            this.updatePageMeta(this.state.pages[this.props.match.params.theme]['children'][this.props.match.params.topic]);
          }

          resolve(response);
        } else {
          reject({
            status: request.status,
            statusText: request.statusText
          });
        }
      } else {
        console.log(JSON.parse(request.responseText));
        reject({
          status: request.status,
          statusText: request.statusText
        });
      }
    }.bind(this);

    request.onerror = function () {
      reject({
        status: request.status,
        statusText: request.statusText
      });
    };

    request.send();
  });
}
