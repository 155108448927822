import React from "react";

const Pregnant = props => (
  <svg width={137} height={141} viewBox="0 0 137 141" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M111.355 75.766c-.016 12.837-10.405 23.24-23.226 23.255a4.648 4.648 0 0 1-4.645-4.651v-4.724a4.648 4.648 0 0 1 4.645-4.651c5.13 0 9.291-4.164 9.291-9.3v-9.231a6.97 6.97 0 0 1 6.967-6.976h.065a6.995 6.995 0 0 1 6.903 6.976v9.302zm-41.806 25.58c-5.131 0-9.29-4.164-9.29-9.303 0-5.136 4.159-9.3 9.29-9.3 5.13 0 9.29 4.164 9.29 9.3 0 5.139-4.16 9.303-9.29 9.303zm5.126-56.293a2.61 2.61 0 0 1 0-3.687 2.603 2.603 0 0 1 3.683 0l2.804 2.806 2.802-2.806a2.603 2.603 0 0 1 3.685 0 2.61 2.61 0 0 1 0 3.687l-6.487 6.495-6.487-6.495zm29.816 9.784a11.926 11.926 0 0 0-8.275 3.367 11.553 11.553 0 0 0-3.441 8.26v2.325H50.969a2.324 2.324 0 0 1-2.323-2.325 2.324 2.324 0 0 1 2.323-2.325h25.547c3.849 0 6.968-3.124 6.968-6.977v-1.363l7.45-7.457a7.268 7.268 0 0 0 0-10.265 7.411 7.411 0 0 0-9.772-.448 7.412 7.412 0 0 0-9.772.438 7.267 7.267 0 0 0 0 10.265l7.448 7.467v1.363a2.324 2.324 0 0 1-2.322 2.326H50.97A6.972 6.972 0 0 0 44 66.464a6.973 6.973 0 0 0 6.97 6.977h41.805v2.253a4.648 4.648 0 0 1-4.646 4.652 9.233 9.233 0 0 0-7.27 3.58c-4.479-6.266-13.181-7.711-19.44-3.226-6.26 4.485-7.702 13.2-3.222 19.466 4.478 6.267 13.183 7.712 19.442 3.226a13.906 13.906 0 0 0 3.249-3.265 9.232 9.232 0 0 0 7.242 3.544c15.386-.016 27.854-12.5 27.871-27.905v-9.302c-.02-6.373-5.144-11.55-11.51-11.627z" />
      <path
        d="M22.876 15.132A18.584 18.584 0 0 0 30.57.089h-4.655c-.008 7.708-6.255 13.955-13.962 13.964H9.625v4.654h2.328c28.262.034 51.166 22.937 51.2 51.2v.347c0 28.086-22.767 50.853-50.851 50.854-6.616.004-11.979 5.365-11.986 11.98v6.638h4.655v-6.638a7.335 7.335 0 0 1 7.325-7.327c30.657.002 55.51-24.849 55.51-55.504v-.35c-.037-26.623-18.828-49.532-44.93-54.775"
        mask="url(#b)"
        transform="translate(69 1)"
      />
      <path d="M81.5 112.35c-23.103-.027-41.823-18.747-41.85-41.85 0-23.114 18.736-41.85 41.85-41.85 23.112 0 41.85 18.736 41.85 41.85 0 23.112-18.738 41.85-41.85 41.85m0-88.35C55.818 24 35 44.818 35 70.5 35 96.181 55.818 117 81.5 117c25.67-.028 46.472-20.83 46.5-46.5C128 44.818 107.18 24 81.5 24" />
      <path
        d="M12.45 69.035a67.688 67.688 0 0 0 6.132-49.905L13.624.223 9.144 1.41l4.955 18.905a63.062 63.062 0 0 1-5.71 46.479 67.562 67.562 0 0 0-5.76 51.27L9.156 141l4.468-1.275-6.537-22.937a62.926 62.926 0 0 1 5.363-47.753"
        mask="url(#d)"
      />
    </g>
  </svg>
);

export default Pregnant;
