import React from "react";

const SocialImpact = props => (
  <svg width={137} height={141} viewBox="0 0 81.02 84.42" {...props}>
    <g data-name="Layer 2">
      <g
        data-name="Layer 1"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M44.29 5.87a11.94 11.94 0 0 1 3.77 0c5.42.91 9.21 5.2 8.48 9.59a7.13 7.13 0 0 1-2.93 4.57l.22 4.5-3.63-2.89a11.82 11.82 0 0 1-4.8.17 11.59 11.59 0 0 1-5.15-2.19m-9.2 23.49a2.05 2.05 0 0 1-2.06 2h-1.46a10.39 10.39 0 0 1-10.48 10.37A10.39 10.39 0 0 1 6.57 45.16H5.11a2.05 2.05 0 0 1-2.06-2 2 2 0 0 1 2.06-2.06h.15v-2.54c0-6.68 5.41-11.07 12.1-11.07h4.89c3.65 0 6.6 4 6.6 7.63v5.93H29a2 2 0 0 1 2.06 2.06z"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2} />
        <path d="M5.11 41.05a4.62 4.62 0 0 0 3.1.41 1.09 1.09 0 0 0 .79-1.3c-.24-1.12-.3-3 1.87-3.41C14 36.23 22.76 42 28.8 35.37m-6.63 8.26v1.05m-7.41-1.05v1.05m5.75 5.77a3.77 3.77 0 0 1-2.44.82 3.71 3.71 0 0 1-2.51-.89m-3.64 7.08v-3.29m10.25 0v3.29M6.26 64.18l2.59 3.46v14.75"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2} />
        <path d="M33.1 82.38V64.61a4.54 4.54 0 0 0-3-4.27L25.6 59l-2.15-2.58-6.4 5.17-6.4-5.17L8.5 59 4 60.34a4.54 4.54 0 0 0-3 4.27v17.77"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2} />
        <path d="M27.84 64.18l-2.59 3.46v14.75m24.36-39.28a2.05 2.05 0 0 0 2.06 2h1.46a10.48 10.48 0 0 0 21 0h1.46a2.05 2.05 0 0 0 2.06-2 2 2 0 0 0-2.06-2.06h-.14v-2.49a11.07 11.07 0 0 0-11.11-11.07A12.52 12.52 0 0 0 51.82 40v1h-.15a2 2 0 0 0-2.06 2.06zm8.88.52v1.05m7.41-1.05v1.05m-5.74 5.77a4 4 0 0 0 4.94-.07"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2} />
        <path d="M51.82 41.05c4.84 0 9.11-6 9.11-6s4.17 6 14.48 6m-5.13-11.82a4.44 4.44 0 0 0 .36-1.74c0-2.88-2.82-5.23-6.3-5.23S58 24.61 58 27.49a4.48 4.48 0 0 0 .32 1.51m.8 26.48v1.24c0 1.94-1.13 2.67-2.89 3.49l-8 3.81A3.71 3.71 0 0 0 46 67.39v5.2h6.54m.04-4.96v15.79m-5.1-10.83v10.83m19.46-27.94v1.24c0 1.94 1.13 2.67 2.89 3.49l8 3.81A3.71 3.71 0 0 1 80 67.39v5.2h-6.52m0-4.96v15.79m5.1-10.83v10.83"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2} />
        <path d="M56.23 60.21l3.57 4.14a4 4 0 0 0 5.85.17l4.18-4.31M31.4 1.22c-7 1.16-11.83 6.67-10.89 12.31a9.19 9.19 0 0 0 3.76 5.86L24 25.17l4.66-3.75a15.25 15.25 0 0 0 6.16.22C41.76 20.48 46.63 15 45.69 9.32S38.35.06 31.4 1.22z"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2} />
      </g>
    </g>
  </svg>
);

export default SocialImpact;
