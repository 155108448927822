import React from 'react';
import CSSTransitionGroup from 'react-transition-group';

import ThemeLink from './ThemeLink.js';

class Welcome extends React.Component {
  render () {
    // Get an object containing all top-level sections or 'themes'
    let childThemes = this.props.pages;
    return (
      <main>
        <div className="content-wrap">
          <div className="welcome-container">
              <div className="themes-list__item themes-list__item--intro intro">
                <div className="intro__inner">
                  <h1 className="intro__title">Welcome</h1>
                  <p className="intro__body">
                    {/* new line to <br /> */}
                    {this.props.welcomeText.split('\n').map(function (item, i) {
                      return (<span key={i}>{item}<br /></span>)
                    })}
                  </p>
                </div>
              </div>
              {childThemes.map(function (theme) {
                return (
                  <ThemeLink theme={theme} key={theme.id} />
                );
              })}
          </div>
        </div>
      </main>
    );
  }
}

export default Welcome;
