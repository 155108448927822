export default function mergeArraysOfObjects (array1, array2, uniqueKey) {
  var newArray = [];

  for (var i in array1) {
    var shared = false;

    for (var j in array2) {
      if (array2[j][uniqueKey] === array1[i][uniqueKey]) {
        shared = true;
        break;
      }
    }

    if (!shared) {
      newArray.push(array1[i]);
    }
  }

  return newArray.concat(array2);
}
