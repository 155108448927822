import React from "react";

const Hospital = props => (
  <svg width={112} height={120} viewBox="0 0 112 120" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M92 60h8v-4h-8v4zm-4 4h16V52H88v12zm4 12h8v-4.001h-8v4zm-4 4h16V68H88v12zm4 12h8v-4h-8v4zm-4 4h16V84H88v12zm4 12.001h8v-4h-8v4zM88 112h16v-12H88v12zM72 31.999H60v12h-8V32H40v-8h12V12h8v12h12v8zm-8-12V8H48v12H36v16h12v12h16V36h12V20H64z" />
      <path d="M28 0v44H0v76h112V44H84V0H28zm4 4h48v112h-8V76H40v40h-8V4zm52 112h24V48H84v68zm-80 0h24V48H4v68zm54 0h10V80H58v36zm-14 0h10V80H44v36z" />
      <path d="M12 60h8v-4h-8v4zm-4 4h16V52H8v12zm4 12h8v-4.001h-8v4zm-4 4h16V68H8v12zm4 12h8v-4h-8v4zm-4 4h16V84H8v12zm4 12.001h8v-4h-8v4zM8 112h16v-12H8v12z" />
    </g>
  </svg>
);

export default Hospital;
