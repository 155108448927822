import React from "react";

const DietaryApproaches = props => (
  <svg data-name="Layer 1" width={137} height={141} viewBox="0 0 105.91 86.14" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1-2">
        <path
          d="M40.51 35.85a9.78 9.78 0 0 1 10.1 1.27 2.17 2.17 0 0 1 0-.51 9.75 9.75 0 0 1 19.5 0 9.59 9.59 0 0 1-.66 3.51 9.52 9.52 0 0 1 4.86-1.3A9.75 9.75 0 0 1 82.7 53.5"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
        />
        <path
          d="M9.31 63.23a6.53 6.53 0 0 1-.71-3 7.44 7.44 0 0 1 1-3.2c.2-.37.39-.75.59-1.14a6.77 6.77 0 0 0 .47-1.15 3.83 3.83 0 0 0 .17-1.16 2.57 2.57 0 0 0-.28-1.15 2.28 2.28 0 0 0-1.74-1.4 4.17 4.17 0 0 0-2.64.46 5.49 5.49 0 0 0-1.34.89 7.87 7.87 0 0 0-1 1 5.11 5.11 0 0 0-.62 1.09 1.12 1.12 0 0 1-1.36.65l-.36-.09a1.12 1.12 0 0 1-.74-1.4v-.09a6.91 6.91 0 0 1 .79-1.51 9.68 9.68 0 0 1 1.39-1.5A8.3 8.3 0 0 1 5 49.13a8.41 8.41 0 0 1 2.64-.8 6 6 0 0 1 2.32.15 4.74 4.74 0 0 1 1.86 1 4.94 4.94 0 0 1 1.26 1.64 5.64 5.64 0 0 1 .51 1.55 5.1 5.1 0 0 1 0 1.46 5.72 5.72 0 0 1-.4 1.47c-.21.49-.45 1-.74 1.57l-.64 1.27a4.22 4.22 0 0 0-.39 1.15 3.29 3.29 0 0 0 0 1.17 4.17 4.17 0 0 0 .44 1.27l.37.77-2.54 1.23zm2.77 2.5a1.74 1.74 0 0 1 2.31.81 1.72 1.72 0 0 1-.81 2.3 1.73 1.73 0 0 1-2.31-.8 1.72 1.72 0 0 1 .79-2.3z"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.39}
        />
        <path
          d="M93.66 46.23a5.56 5.56 0 0 1 2-1.73 6.27 6.27 0 0 1 2.87-.53h1.09a6.35 6.35 0 0 0 1.07-.09 3.16 3.16 0 0 0 1-.32 2.29 2.29 0 0 0 .78-.67 2 2 0 0 0 .33-1.86 3.56 3.56 0 0 0-1.38-1.83 4.24 4.24 0 0 0-1.21-.68 6.27 6.27 0 0 0-1.21-.29 5.6 5.6 0 0 0-1-.06 1 1 0 0 1-1-.79l-.15-.35a1 1 0 0 1 .82-1.12 6.84 6.84 0 0 1 1.43 0 8.7 8.7 0 0 1 1.74.44 6.9 6.9 0 0 1 1.89 1.06 7.06 7.06 0 0 1 1.65 1.71 5.07 5.07 0 0 1 .79 1.83 3.91 3.91 0 0 1 0 1.8 4.4 4.4 0 0 1-.75 1.61 5.26 5.26 0 0 1-1 1 4.26 4.26 0 0 1-1.12.58 5 5 0 0 1-1.28.26 14.84 14.84 0 0 1-1.49 0h-1.18a3.78 3.78 0 0 0-1 .15 3.48 3.48 0 0 0-.91.44 4.18 4.18 0 0 0-.84.84l-.43.58-2-1.43zm-.82 3.09a1.47 1.47 0 0 1 .28 2.07 1.47 1.47 0 0 1-2.06.29 1.48 1.48 0 0 1-.3-2.07 1.49 1.49 0 0 1 2.08-.29z"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.28}
        />
        <path
          d="M46.34 19.15a8.1 8.1 0 0 1 .45-3.84 9 9 0 0 1 2.49-3.4l1.16-1.08a8.33 8.33 0 0 0 1-1.14 4.83 4.83 0 0 0 .68-1.29 3.45 3.45 0 0 0 .16-1.46 2.93 2.93 0 0 0-1.45-2.26 5.23 5.23 0 0 0-3.27-.56 6.29 6.29 0 0 0-2 .48 9.24 9.24 0 0 0-1.56.83 7.09 7.09 0 0 0-1.19 1 1.37 1.37 0 0 1-1.81.2l-.41-.3a1.37 1.37 0 0 1-.33-1.91l.07-.09a8.08 8.08 0 0 1 1.53-1.39 11.3 11.3 0 0 1 2.34-1.28 10.41 10.41 0 0 1 3-.75 9.64 9.64 0 0 1 3.47.12 7.76 7.76 0 0 1 2.64 1.14 6 6 0 0 1 1.77 1.91 6.2 6.2 0 0 1 .79 2.44 6.7 6.7 0 0 1-.06 2 6.44 6.44 0 0 1-.6 1.71 7.94 7.94 0 0 1-1.09 1.54c-.44.49-.95 1-1.52 1.53l-1.28 1.21a5.9 5.9 0 0 0-.93 1.18 4.67 4.67 0 0 0-.52 1.34 5.45 5.45 0 0 0 0 1.71l.11 1-3.48.38zm2.19 4.07a2.15 2.15 0 0 1 2.35 1.9A2.14 2.14 0 0 1 49 27.48a2.15 2.15 0 0 1-2.36-1.9 2.16 2.16 0 0 1 1.89-2.36z"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.61}
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
          d="M78.87 53.5l5.82-33.77-5.59-.96-5.98 34.73"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
          d="M84.62 53.49l4.7-27.3-5.58-.96-4.87 28.27"
        />
        <path
          d="M43.2 46.39a9.43 9.43 0 0 1 7.66-3.81 9.54 9.54 0 0 1 6.89 2.87"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
        />
        <path
          d="M53 53.5v-.12c0-4.77 3.52-8.64 7.87-8.64s7.86 3.87 7.86 8.64v.12"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
        />
        <path
          d="M56.05 37.9c0-3.51 2.25-5.76 4.77-5.76"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
        />
        <path
          d="M29.52 38.65a6.53 6.53 0 0 1 12.64.43c.33 1.45 1.19 7.62 1.85 14.42M22.84 24.81c4.93-1.11 9.37 2.39 11.45 9.34m.26-.04c-.54-6.18 2.27-9.31 6.93-10.35m-9.02 20.01l4.57-1.19"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
        />
        <path
          d="M25.71 53.5c-4.25-4.72-7.84-9-8.65-10.17a6.541 6.541 0 0 1 10.39-7.95c.1.13.19.26.28.4 1.1 1.56 6 9.9 9.92 17.72M4.4 30.03c4.13-2.9 9.6-1.37 14.17 4.23m.23-.13c-2.89-5.5-1.49-9.47 2.41-12.23m-2.58 23.43l5.72-4.34M23.01 53.5h63.44v31.57H23.01z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.14}
          d="M23.01 64.06h63.44v10.43H23.01z"
        />
        <circle cx={29.33} cy={58.78} r={1.29}
          fill="currentColor"
          stroke="none" />
        <circle cx={29.33} cy={69.35} r={1.29}
          fill="currentColor"
          stroke="none" />
        <circle cx={29.33} cy={79.91} r={1.29}
          fill="currentColor"
          stroke="none" />
        <circle cx={80.13} cy={58.78} r={1.29}
          fill="currentColor"
          stroke="none" />
        <circle cx={80.13} cy={69.35} r={1.29}
          fill="currentColor"
          stroke="none" />
        <circle cx={80.13} cy={79.91} r={1.29}
          fill="currentColor"
          stroke="none" />
      </g>
    </g>
  </svg>
);

export default DietaryApproaches;
