import React from "react";

const Search = props => (
  <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
    <path
      d="M2.379 7.642A5.268 5.268 0 0 1 7.64 2.379a5.268 5.268 0 0 1 5.261 5.263 5.267 5.267 0 0 1-5.26 5.261 5.267 5.267 0 0 1-5.263-5.261m15.272 8.327l-3.84-3.84a7.59 7.59 0 0 0 1.471-4.487C15.282 3.429 11.855 0 7.642 0 3.427 0 0 3.429 0 7.642c0 4.212 3.428 7.64 7.641 7.64 1.678 0 3.226-.55 4.488-1.47l3.84 3.84a1.187 1.187 0 0 0 1.682 0 1.19 1.19 0 0 0 0-1.683"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Search;
